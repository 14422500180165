@font-face {
  font-family: "Inter Light";
  src: url("./fonts/Inter/Inter-Light.ttf");
  font-style: medium;
  font-display: swap;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Inter Regular";
  src: url("./fonts/Inter/Inter-Regular.ttf");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Inter Medium";
  src: url("./fonts/Inter/Inter-Medium.ttf");
  font-display: swap;
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Inter Bold";
  src: url("./fonts/Inter/Inter-Bold.ttf");
  font-display: swap;
  font-style: medium;
  font-weight: 600;
  font-display: swap;
}

body,
html {
  font-family: "Inter Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.App,
.Dashboard {
  min-height: 100vh;
  background: hsla(178, 58%, 10%, 1);
  background: radial-gradient(
    circle,
    hsla(178, 58%, 10%, 1) 0%,
    hsla(0, 0%, 0%, 1) 100%
  );
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
