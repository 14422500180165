.selected > svg {
  stroke: #10e3a4;
}

.selected {
  position: relative;
}

.selected:after {
  content: "";
  position: absolute;
  width: 5px;
  background-color: #10e3a4;
  right: 0;
  height: 50%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
